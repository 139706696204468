var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.stickyScroll,
      expression: "stickyScroll"
    }],
    staticClass: "v-tabs-sticky"
  }, [_c('div', {
    staticClass: "v-tabs-sticky__inner"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }