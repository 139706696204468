var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "About Us",
            "tabActive": "Values",
            "src": "/images/sub/sv-story-values-vision-challenge.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-depth-tab-wrap', [_c('v-container', [_c('story-values-chips', {
    attrs: {
      "tabActive": "vision"
    }
  })], 1)], 1), _c('sub-depth-tab-wrap', [_c('v-tabs-sticky', [_c('story-values-vision-tabs')], 1)], 1), _c('sub-section', {
    staticClass: "page-section--first"
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Exports to 70 countries around the World "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("The Global Dentium")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 py-lg-60 px-lg-40"
  }, [_c('div', {
    staticClass: "mb-16 mb-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "60",
      "src": "/images/sub/story/values/challenge-icon.png"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-2--text"
  }, [_vm._v("US/China Global "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("Production Base")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 py-lg-60 px-lg-40"
  }, [_c('div', {
    staticClass: "mb-16 mb-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "60",
      "src": "/images/sub/story/values/challenge-icon2.png"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-2--text"
  }, [_vm._v("Dentium Institute (Dentium World Symposium Dentium Forum, etc)")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 py-lg-60 px-lg-40"
  }, [_c('div', {
    staticClass: "mb-16 mb-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "60",
      "src": "/images/sub/story/values/challenge-icon3.png"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-2--text"
  }, [_vm._v("Bone fusion grafting material professional development")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 py-lg-60 px-lg-40"
  }, [_c('div', {
    staticClass: "mb-16 mb-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "60",
      "src": "/images/sub/story/values/challenge-icon4.png"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-2--text"
  }, [_vm._v("14 overseas subsidiaries export more than 70 countries around the world. Technical delegates, including direct sales force")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 py-lg-60 px-lg-40"
  }, [_c('div', {
    staticClass: "mb-16 mb-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "60",
      "src": "/images/sub/story/values/challenge-icon5.png"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-2--text"
  }, [_vm._v("Establishing a clinical research network "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("(leading overseas universities and "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("domestic dentistry)")])])])])], 1)], 1)], 1), _c('sub-section', {
    staticClass: "pt-0 grey-f8"
  }, [_c('div', {
    staticClass: "img-wrap--half-bg mb-30 mb-md-56"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/story/values/challenge-img.png"
    }
  })], 1), _c('div', {
    staticClass: "sub-tit-wrap"
  }, [_c('h3', {
    staticClass: "sub-tit"
  }, [_vm._v("Challenge")]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("2004 Establishment of the first U.S.production plant in Korea, 2003 U.S. FDA, European CE, and more Obtain certification of major overseas countries")])])]), _c('div', {
    staticClass: "page-text"
  }, [_c('p', [_vm._v("In 2004, the local Industry's First set up a production plant in the United States and strict fda the United States. Food and Drug Administration certification is required in major countries, including overseas. A global technology and products based on mutual trust. Europe, Central Asia, Russia, the Middle East, Asia, etc. Export in over seventy countries all over the world. Korea's leading implants.")])])]), _c('sub-section', {
    staticClass: "page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap text-center mb-40 mb-md-80 mb-lg-110"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Dentium, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("Opening a new Generation")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "img-wrap img-wrap--left-gray-bg"
  }, [_c('v-img', {
    attrs: {
      "max-width": "408",
      "src": "/images/sub/story/values/challenge-img2.png"
    }
  })], 1)]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "mt-20 mt-md-0"
  }, [_c('div', {
    staticClass: "sub-tit-wrap"
  }, [_c('h3', {
    staticClass: "sub-tit"
  }, [_vm._v("Global")]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("Opening a new Generation."), _c('br'), _vm._v("To enter the global TOP6 in 2020")])])]), _c('div', {
    staticClass: "page-text"
  }, [_c('p', [_vm._v("To enter the global TOP6 in 2020, we secured differentiated technology through our own R&D We are committed to providing the highest quality implant system. In addition to the widely recognized dental implant products, dentium has also been extensively studied in biomaterials for bone grafts, We are developing various bone regeneration and periodontal tissue regeneration products such as shielding membranes.")]), _c('p', [_vm._v("In addition, in 2010, the Institute of Convergence Technology (ICT) Business Headquarters was established to provide a three-dimensional radiation machine called CBCT CAD/CAM systems are self-developed and integrated implant systems are established in conjunction with implant systems 'Total Solution Provider & Self Development Manufacturer' is being realized.")])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }