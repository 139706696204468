<template>
    <div v-scroll="stickyScroll" class="v-tabs-sticky">
        <div class="v-tabs-sticky__inner">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {};
    },
    methods: {
        stickyScroll: function() {
            var VTabsSticky = document.querySelector(".v-tabs-sticky");
            var VTabsStickyOffsetTop = VTabsSticky.offsetTop;
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > VTabsStickyOffsetTop - 100) {
                VTabsSticky.classList.add("scroll-active");
            } else {
                VTabsSticky.classList.remove("scroll-active");
            }
        },
    },
};
</script>

<style scoped>
.v-tabs-sticky {
    position: relative;
    z-index: 1;
    height: 48px;
}
.v-tabs-sticky.scroll-active .v-tabs-sticky__inner {
    position: fixed;
    top: var(--header-body);
    left: 0;
    width: 100%;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .v-tabs-sticky {
        height: 60px;
    }
}
@media (min-width: 1200px) {
}
</style>
