var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tab-link-group', _vm._b({
    attrs: {
      "tabsGrey90": ""
    }
  }, 'tab-link-group', {
    tabs: _vm.tabs
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }