var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-lg-6 grey-f8"
  }, [_c('v-tabs', {
    attrs: {
      "hide-slider": "",
      "centered": "",
      "show-arrows": ""
    }
  }, [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }