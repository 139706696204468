var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tabsGrey90 ? _c('v-tabs-grey', _vm._b({}, 'v-tabs-grey', {
    value: _vm.value
  }, false), _vm._l(_vm.tabs, function (_ref) {
    var text = _ref.text,
      value = _ref.value,
      to = _ref.to;
    return _c('v-tab', {
      key: value
    }, [_c('v-btn', _vm._b({
      staticClass: "v-btn--none-active-bg",
      attrs: {
        "text": "",
        "color": "grey-90"
      }
    }, 'v-btn', Object.assign({}, _vm.$attrs, {
      value,
      to
    }), false), [_vm._v(_vm._s(text))])], 1);
  }), 1) : _c('v-tabs', _vm._b({}, 'v-tabs', {
    value: _vm.value
  }, false), _vm._l(_vm.tabs, function (_ref2) {
    var text = _ref2.text,
      value = _ref2.value,
      to = _ref2.to;
    return _c('v-tab', {
      key: value
    }, [_c('v-btn', _vm._b({
      staticClass: "v-btn--none-active-bg",
      attrs: {
        "text": "",
        "color": "primary"
      }
    }, 'v-btn', Object.assign({}, _vm.$attrs, {
      value,
      to
    }), false), [_vm._v(_vm._s(text))])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }