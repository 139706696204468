<template>
    <div class="py-lg-6 grey-f8">
        <v-tabs hide-slider centered show-arrows>
            <slot />
        </v-tabs>
    </div>
</template>

<script>
export default {
    props: {
    },
};
</script>

<style scoped>
>>>.v-tabs>.v-tabs-bar{
    background-color: transparent;
}
>>>.v-tab::after{
    content:'';
    position:absolute;
    top:50%;
    left:0;
    -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
    display: block;
    width: 1px;
    height: 12px;
    background-color: #ddd;
}
>>>.v-tabs-bar__content>:first-child::after{display:none;}
>>>.v-tab .v-btn--active{
    color: #222 !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    >>>.v-tab{
        padding:0 10px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
