var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "About Us",
            "tabActive": "Values",
            "src": "/images/sub/sv-story-values-rnd.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-depth-tab-wrap', [_c('v-container', [_c('story-values-chips')], 1)], 1), _c('sub-section', {
    staticClass: "page-section--first pb-0",
    staticStyle: {
      "background-image": "url(/images/sub/story/values/rnd-bg.jpg)"
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-lg-60 text-center"
  }, [_c('h3', {
    staticClass: "tit tit--sm secondary--text mb-10 mb-lg-14"
  }, [_vm._v("Research")]), _c('h2', {
    staticClass: "tit font-size-lg-52"
  }, [_vm._v("Global Dentium")])]), _c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mr-lg-0",
    attrs: {
      "max-width": "486",
      "aspect-ratio": 486 / 474,
      "src": "/images/sub/story/values/rnd-img.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "pt-10 pt-md-0"
  }, [_c('v-divider', {
    staticClass: "primary mb-16 mb-md-24 w-30px",
    staticStyle: {
      "border-width": "4px 0 0"
    }
  }), _c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("Dentium is playing a leading role by facilitating the sharing of implant information between dental scholars and specialists from various countries for the advancement of the dental industry.")])], 1)])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-40 mt-lg-60"
  }, [_c('v-img', {
    staticClass: "mx-auto mr-lg-0",
    attrs: {
      "max-width": "1200",
      "aspect-ratio": 1200 / 638,
      "src": "/images/sub/story/values/rnd-img2.png"
    }
  })], 1)]), _c('sub-section', {
    staticClass: "page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Dentium R&D Process")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center mr-n24",
    staticStyle: {
      "position": "relative",
      "z-index": "1"
    },
    attrs: {
      "width": "48",
      "height": "48",
      "color": "primary",
      "rounded": "circle"
    }
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium white--text"
  }, [_vm._v("01")])]), _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": "",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-20 pl-36 px-md-82 py-md-32"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-20 pr-md-30 mb-6 mb-sm-0"
  }, [_c('v-img', {
    attrs: {
      "width": "48",
      "max-height": "48",
      "src": "/images/sub/story/values/rnd-icon.png"
    }
  })], 1)]), _c('v-col', [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6"
  }, [_vm._v("Market Survey")]), _c('p', {
    staticClass: "page-text grey-90--text"
  }, [_vm._v("Identification of customer’s needs and demands")])])], 1)], 1)])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center mr-n24",
    staticStyle: {
      "position": "relative",
      "z-index": "1"
    },
    attrs: {
      "width": "48",
      "height": "48",
      "color": "primary",
      "rounded": "circle"
    }
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium white--text"
  }, [_vm._v("02")])]), _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": "",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-20 pl-36 px-md-82 py-md-32"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-20 pr-md-30 mb-6 mb-sm-0"
  }, [_c('v-img', {
    attrs: {
      "width": "36",
      "max-height": "48",
      "src": "/images/sub/story/values/rnd-icon2.png"
    }
  })], 1)]), _c('v-col', [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6"
  }, [_vm._v("Design & Development of Advanced Materials")]), _c('p', {
    staticClass: "page-text grey-90--text"
  }, [_vm._v("Determination and production of design concept and goal")])])], 1)], 1)])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center mr-n24",
    staticStyle: {
      "position": "relative",
      "z-index": "1"
    },
    attrs: {
      "width": "48",
      "height": "48",
      "color": "primary",
      "rounded": "circle"
    }
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium white--text"
  }, [_vm._v("03")])]), _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": "",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-20 pl-36 px-md-82 py-md-32"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-20 pr-md-30 mb-6 mb-sm-0"
  }, [_c('v-img', {
    attrs: {
      "width": "46",
      "max-height": "48",
      "src": "/images/sub/story/values/rnd-icon3.png"
    }
  })], 1)]), _c('v-col', [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6"
  }, [_vm._v("Evaluation & Verification")]), _c('p', {
    staticClass: "page-text grey-90--text"
  }, [_vm._v("Validation through collaboration with governments,universities, and hospitals")])])], 1)], 1)])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center mr-n24",
    staticStyle: {
      "position": "relative",
      "z-index": "1"
    },
    attrs: {
      "width": "48",
      "height": "48",
      "color": "primary",
      "rounded": "circle"
    }
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium white--text"
  }, [_vm._v("04")])]), _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": "",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-20 pl-36 px-md-82 py-md-32"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-20 pr-md-30 mb-6 mb-sm-0"
  }, [_c('v-img', {
    attrs: {
      "width": "40",
      "max-height": "48",
      "src": "/images/sub/story/values/rnd-icon4.png"
    }
  })], 1)]), _c('v-col', [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6"
  }, [_vm._v("Certification & Authorization")]), _c('p', {
    staticClass: "page-text grey-90--text"
  }, [_vm._v("Recognition from governmental regulatory affairs")])])], 1)], 1)])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center mr-n24",
    staticStyle: {
      "position": "relative",
      "z-index": "1"
    },
    attrs: {
      "width": "48",
      "height": "48",
      "color": "primary",
      "rounded": "circle"
    }
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium white--text"
  }, [_vm._v("05")])]), _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": "",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-20 pl-36 px-md-82 py-md-32"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-20 pr-md-30 mb-6 mb-sm-0"
  }, [_c('v-img', {
    attrs: {
      "width": "48",
      "max-height": "48",
      "src": "/images/sub/story/values/rnd-icon5.png"
    }
  })], 1)]), _c('v-col', [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6"
  }, [_vm._v("Product Evaluation in the Market")]), _c('p', {
    staticClass: "page-text grey-90--text"
  }, [_vm._v("Reflection of customer’s complaints and suggestions")])])], 1)], 1)])], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }