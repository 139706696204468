<template>
    <tab-link-group v-bind="{ tabs }" tabsGrey90 />
</template>

<script>
import tabLinkGroup from "../dumb/tab-link-group.vue";

const tabs = [
    { text: "Trust", value: "trust", to: "/story/values/vision/trust" },
    { text: "Challenge", value: "challenge", to: "/story/values/vision/challenge" },
];
export default {
    components: {
        tabLinkGroup,
    },
    data: () => ({
        tabs,
    }),
};
</script>

<style></style>
