<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="About Us" tabActive="Values" src="/images/sub/sv-story-values-rnd.png"></sub-visual>
        </template>

        <sub-depth-tab-wrap>
            <v-container>
                <story-values-chips />
            </v-container>
        </sub-depth-tab-wrap>

        <sub-section class="page-section--first pb-0" style="background-image:url(/images/sub/story/values/rnd-bg.jpg);">
            <div class="tit-wrap mb-lg-60 text-center">
                <h3 class="tit tit--sm secondary--text mb-10 mb-lg-14">Research</h3>
                <h2 class="tit font-size-lg-52">Global Dentium</h2>
            </div>

            <div>
                <v-row align="center">
                    <v-col cols="12" md="6" order-md="2">
                        <v-img max-width="486" :aspect-ratio="486 / 474" class="mx-auto mr-lg-0" src="/images/sub/story/values/rnd-img.png" />
                    </v-col>
                    <v-col cols="12" md="6" order-md="1">
                        <div class="pt-10 pt-md-0">
                            <v-divider class="primary mb-16 mb-md-24 w-30px" style="border-width:4px 0 0;" />
                            <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">Dentium is playing a leading role by facilitating the sharing of implant information between dental scholars and specialists from various countries for the advancement of the dental industry.</p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-30 mt-md-40 mt-lg-60">
                <v-img max-width="1200" :aspect-ratio="1200 / 638" class="mx-auto mr-lg-0" src="/images/sub/story/values/rnd-img2.png" />
            </div>
        </sub-section>

        <!-- <sub-section class="page-section--first pb-0">
            <div class="tit-wrap text-center mb-40 mb-md-80 mb-lg-110">
                <h2 class="tit">Market-leading Innovation <br class="d-none d-md-block"/>Dentium R&amp;D Center</h2>
            </div>
        </sub-section>

        <sub-section class="pt-0 grey-f8">
            <div class="img-wrap--half-bg mb-30 mb-md-56">
                <v-img src="/images/sub/story/values/rnd-img.png" />
            </div>
            <div class="sub-tit-wrap">
                <h3 class="sub-tit">R&amp;D</h3>
                <div class="tit-wrap">
                    <h2 class="tit tit--sm">True value to make dentium of innovation continues</h2>
                </div>
            </div>
            <div class="page-text">
                <p>The Dentium R&amp;D center is composed of professional researchers with advanced facilities systems that enable stability, validation and creative innovation. In cooperation with leading universities and research institutes in the world, we have developed implant systems, including artificial teeth, surgical kits, bone grafts, and membranes, providing a faster and more aesthetic implant treatment environment and materials.</p>
            </div>
        </sub-section> -->

        <sub-section class="page-section--last">
            <div class="tit-wrap tit-wrap--hyphen">
                <h2 class="tit tit--xs">Dentium R&amp;D Process</h2>
            </div>

            <v-row>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-sheet width="48" height="48" color="primary" rounded="circle" class="d-flex align-center justify-center mr-n24" style="position:relative; z-index:1;"><span class="font-size-16 font-size-md-18 font-weight-medium white--text">01</span></v-sheet>
                        <v-card tile outlined width="100%">
                            <div class="pa-20 pl-36 px-md-82 py-md-32">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" sm="auto">
                                        <div class="pr-20 pr-md-30 mb-6 mb-sm-0"><v-img width="48" max-height="48" src="/images/sub/story/values/rnd-icon.png" /></div>
                                    </v-col>
                                    <v-col>
                                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6">Market Survey</div>
                                        <p class="page-text grey-90--text">Identification of customer’s needs and demands</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-sheet width="48" height="48" color="primary" rounded="circle" class="d-flex align-center justify-center mr-n24" style="position:relative; z-index:1;"><span class="font-size-16 font-size-md-18 font-weight-medium white--text">02</span></v-sheet>
                        <v-card tile outlined width="100%">
                            <div class="pa-20 pl-36 px-md-82 py-md-32">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" sm="auto">
                                        <div class="pr-20 pr-md-30 mb-6 mb-sm-0"><v-img width="36" max-height="48" src="/images/sub/story/values/rnd-icon2.png" /></div>
                                    </v-col>
                                    <v-col>
                                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6">Design &amp; Development of Advanced Materials</div>
                                        <p class="page-text grey-90--text">Determination and production of design concept and goal</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-sheet width="48" height="48" color="primary" rounded="circle" class="d-flex align-center justify-center mr-n24" style="position:relative; z-index:1;"><span class="font-size-16 font-size-md-18 font-weight-medium white--text">03</span></v-sheet>
                        <v-card tile outlined width="100%">
                            <div class="pa-20 pl-36 px-md-82 py-md-32">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" sm="auto">
                                        <div class="pr-20 pr-md-30 mb-6 mb-sm-0"><v-img width="46" max-height="48" src="/images/sub/story/values/rnd-icon3.png" /></div>
                                    </v-col>
                                    <v-col>
                                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6">Evaluation &amp; Verification</div>
                                        <p class="page-text grey-90--text">Validation through collaboration with governments,universities, and hospitals</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-sheet width="48" height="48" color="primary" rounded="circle" class="d-flex align-center justify-center mr-n24" style="position:relative; z-index:1;"><span class="font-size-16 font-size-md-18 font-weight-medium white--text">04</span></v-sheet>
                        <v-card tile outlined width="100%">
                            <div class="pa-20 pl-36 px-md-82 py-md-32">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" sm="auto">
                                        <div class="pr-20 pr-md-30 mb-6 mb-sm-0"><v-img width="40" max-height="48" src="/images/sub/story/values/rnd-icon4.png" /></div>
                                    </v-col>
                                    <v-col>
                                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6">Certification &amp; Authorization</div>
                                        <p class="page-text grey-90--text">Recognition from governmental regulatory affairs</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-sheet width="48" height="48" color="primary" rounded="circle" class="d-flex align-center justify-center mr-n24" style="position:relative; z-index:1;"><span class="font-size-16 font-size-md-18 font-weight-medium white--text">05</span></v-sheet>
                        <v-card tile outlined width="100%">
                            <div class="pa-20 pl-36 px-md-82 py-md-32">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" sm="auto">
                                        <div class="pr-20 pr-md-30 mb-6 mb-sm-0"><v-img width="48" max-height="48" src="/images/sub/story/values/rnd-icon5.png" /></div>
                                    </v-col>
                                    <v-col>
                                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text mb-4 mb-md-6">Product Evaluation in the Market</div>
                                        <p class="page-text grey-90--text">Reflection of customer’s complaints and suggestions</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </sub-section>

        <!-- <sub-section class="pt-0">
            <v-row>
                <v-col cols="12" md="8" lg="6" order-md="1">
                    <div class="mt-20 mt-md-0">
                        <div class="sub-tit-wrap">
                            <h3 class="sub-tit">Clinical Research</h3>
                            <div class="tit-wrap">
                                <h2 class="tit tit--sm">Establishing a global clinical network Dentium Clinical Research Center</h2>
                            </div>
                        </div>
                        <div class="page-text">
                            <p>It's a medical product for people, so it's basic to make it well. Dentium is faithful to the basics, and furthermore, we think about how to use it well. Dentium, which has studied only implants for 16 years, is conducting active clinical research with leading domestic and foreign dental schools, including Harvard University in the U.S., and holds seminars and educational promotions on implants with long know-how.</p>
                            <p>Through this, we are contributing to the development of the medical industry by exchanging implant information between scholars and specialists in ental medicine from each country, taking the lead in discovering high-tech technologies.</p>
                        </div>
                    </div>
                </v-col>
                <v-spacer class="order-2" />
                <v-col cols="12" md="4" lg="5" order="1" order-md="3">
                    <div class="img-wrap img-wrap--right-gray-bg">
                        <v-img max-width="408" src="/images/sub/story/values/rnd-img2.png" />
                    </div>
                </v-col>
            </v-row>
        </sub-section> -->

        <!-- <sub-section class="page-section--last pt-0 grey-f8">
            <div class="img-wrap--half-bg mb-30 mb-md-56">
                <v-img src="/images/sub/story/values/rnd-img3.png" />
            </div>
            <div class="sub-tit-wrap">
                <h3 class="sub-tit">Universities</h3>
                <div class="tit-wrap">
                    <h2 class="tit tit--sm">Universities in Collaboration with Dentium</h2>
                </div>
            </div>
            <v-row>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo2.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo3.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo4.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo5.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo6.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo7.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo8.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo9.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo10.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo11.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo12.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo13.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo14.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo15.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo16.png"/></v-col>
                <v-col cols="12" sm="6" md="4"><v-img src="/images/sub/story/values/rnd-logo17.png"/></v-col>
            </v-row>
        </sub-section> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import StoryValuesChips from "@/components/client/sub/story-values-chips.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubDepthTabWrap,
        SubSection,
        StoryValuesChips,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style></style>
