<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="About Us" tabActive="Values" src="/images/sub/sv-story-values-overview.png"></sub-visual>
        </template>

        <sub-depth-tab-wrap>
            <v-container>
                <story-values-chips />
            </v-container>
        </sub-depth-tab-wrap>

        <sub-section class="page-section--first">
            <div class="page-section__bg" style="background-image:url(/images/sub/story/values/overview-bg.jpg);" />
            <div class="tit-wrap mb-lg-60 text-center">
                <h3 class="tit tit--sm secondary--text mb-10 mb-lg-14">Vision</h3>
                <h2 class="tit font-size-lg-52">To be used widely, with good product.</h2>
            </div>

            <div class="mb-30 mb-md-40 mb-lg-60">
                <v-img max-width="1200" :aspect-ratio="1200 / 562" class="mx-auto" src="/images/sub/story/values/overview-img.jpg" />
            </div>

            <v-row no-gutters justify="center">
                <v-col cols="12" sm="6" md="3" align-self="center">
                    <div class="pa-20 px-md-10 py-md-0 text-center">
                        <!-- <div class="mb-16 mb-lg-24">
                                <v-img max-width="44" class="ma-auto" src="/images/sub/story/values/overview-icon.png"></v-img>
                            </div> -->
                        <div class="mb-16 mb-lg-24">
                            <p class="font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1">1</p>
                        </div>
                        <div>
                            <p class="font-size-16 font-size-md-18 font-spoqa font-weight-medium">Quality first <br class="d-sm-none d-md-block" />principle</p>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" class="d-sm-none"><v-divider /></v-col>
                <v-col cols="auto" class="d-none d-sm-block ml-n1"><v-divider vertical/></v-col>
                <v-col cols="12" sm="6" md="3" align-self="center">
                    <div class="pa-20 px-md-10 py-md-0 text-center">
                        <!-- <div class="mb-16 mb-lg-24">
                                <v-img max-width="44" class="ma-auto" src="/images/sub/story/values/overview-icon2.png"></v-img>
                            </div> -->
                        <div class="mb-16 mb-lg-24">
                            <p class="font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1">10</p>
                        </div>
                        <div>
                            <p class="font-size-16 font-size-md-18 font-spoqa font-weight-medium">More than 10 years of <br class="d-sm-none d-lg-block" />maintenance verification</p>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" class="d-md-none"><v-divider /></v-col>
                <v-col cols="auto" class="d-none d-md-block ml-n1"><v-divider vertical/></v-col>
                <v-col cols="12" sm="6" md="3" align-self="center">
                    <div class="pa-20 px-md-10 py-md-0 text-center">
                        <!-- <div class="mb-16 mb-lg-24">
                                <v-img max-width="48" class="ma-auto" src="/images/sub/story/values/overview-icon3.png"></v-img>
                            </div> -->
                        <div class="mb-16 mb-lg-24">
                            <p class="font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1">70</p>
                        </div>
                        <div>
                            <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">Exports to over 70 countries <br class="d-sm-none d-lg-block" />around the world</p>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" class="d-sm-none"><v-divider /></v-col>
                <v-col cols="auto" class="d-none d-sm-block ml-n1"><v-divider vertical/></v-col>
                <v-col cols="12" sm="6" md="3" align-self="center">
                    <div class="pa-20 px-md-10 py-md-0 text-center">
                        <!-- <div class="mb-16 mb-lg-24">
                                <v-img max-width="28" class="ma-auto" src="/images/sub/story/values/overview-icon4.png"></v-img>
                            </div> -->
                        <div class="mb-16 mb-lg-24">
                            <p class="font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1">98</p>
                        </div>
                        <div>
                            <p class="font-size-16 font-size-md-18 font-spoqa font-weight-medium">A high success rate of <br class="d-sm-none d-lg-block" />98 percent</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </sub-section>

        <sub-section style="background-image:url(/images/sub/story/values/overview-bg2.jpg);">
            <div class="mb-40 mb-md-50 mb-lg-90 text-center">
                <v-img max-width="758" :aspect-ratio="758 / 200" class="mx-auto" src="/images/sub/story/values/overview-tit.png" />
            </div>
            <v-row class="my-n16">
                <v-col cols="12" sm="6" md="4" class="py-16">
                    <v-img max-width="384" :aspect-ratio="384 / 208" class="mx-auto" src="/images/sub/story/values/overview-img2.jpg" />
                    <div class="mt-10 mt-md-16 text-center">
                        <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">Pre-op 2001.11.10</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-16">
                    <v-img max-width="384" :aspect-ratio="384 / 208" class="mx-auto" src="/images/sub/story/values/overview-img3.jpg" />
                    <div class="mt-10 mt-md-16 text-center">
                        <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">Implantation 2001.11.28</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-16">
                    <v-img max-width="384" :aspect-ratio="384 / 208" class="mx-auto" src="/images/sub/story/values/overview-img4.jpg" />
                    <div class="mt-10 mt-md-16 text-center">
                        <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">Implantation 2001.11.28</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-16">
                    <v-img max-width="384" :aspect-ratio="384 / 208" class="mx-auto" src="/images/sub/story/values/overview-img5.jpg" />
                    <div class="mt-10 mt-md-16 text-center">
                        <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">4 Year Follow-up 2005.05.23</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-16">
                    <v-img max-width="384" :aspect-ratio="384 / 208" class="mx-auto" src="/images/sub/story/values/overview-img6.jpg" />
                    <div class="mt-10 mt-md-16 text-center">
                        <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">11 Year Follow-up 2013.01.17</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-16">
                    <v-img max-width="384" :aspect-ratio="384 / 208" class="mx-auto" src="/images/sub/story/values/overview-img7.jpg" />
                    <div class="mt-10 mt-md-16 text-center">
                        <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">20 Year Follow-up 2021.04.14</p>
                    </div>
                </v-col>
            </v-row>
        </sub-section>

        <sub-section style="background-image:url(/images/sub/story/values/overview-bg3.jpg);">
            <div class="tit-wrap mb-lg-60 text-center">
                <h2 class="tit font-size-lg-52">Global Dentium</h2>
            </div>

            <div>
                <v-row align="center">
                    <v-col cols="12" md="5" order-md="2">
                        <v-img max-width="486" :aspect-ratio="486 / 486" class="mx-auto" src="/images/sub/story/values/overview-img8.jpg" />
                    </v-col>
                    <v-col cols="12" md="7" order-md="1">
                        <div class="pt-10 pt-md-0">
                            <v-divider class="primary mb-16 mb-md-24 w-30px" style="border-width:4px 0 0;" />
                            <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">Dentium is a manufacturer that provides world-class <br class="d-none d-lg-block" />dental implant systems and solutions to clinicians in over 70 <br class="d-none d-lg-block" />countries worldwide.</p>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="mt-30 mt-md-40 mt-lg-60">
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <v-img max-width="486" :aspect-ratio="486 / 486" class="mx-auto" src="/images/sub/story/values/overview-img9.png" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="pt-10 pt-md-0">
                            <v-divider class="primary mb-16 mb-md-24 w-30px" style="border-width:4px 0 0;" />
                            <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">In the future, Dentium will countinue to progress in creating <br class="d-none d-lg-block" />a practical and use-friendly treatment environment through <br class="d-none d-lg-block" />the simplification and digitalization of the dental industry.</p>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="mt-30 mt-md-40 mt-lg-60">
                <v-row align="center">
                    <v-col cols="12" md="6" order-md="2" class="overview-img-wide">
                        <v-img max-width="834" :aspect-ratio="834 / 600" class="mx-auto mr-lg-0" src="/images/sub/story/values/overview-img10.png" />
                    </v-col>
                    <v-col cols="12" md="6" order-md="1">
                        <div class="pb-lg-200">
                            <v-divider class="primary mb-16 mb-md-24 w-30px" style="border-width:4px 0 0;" />
                            <p class="font-size-16 font-size-lg-18 font-spoqa font-weight-medium">In the future, Dentium will move forward to create a practical and <br class="d-none d-lg-block" />simple treatment environment through minimalism and <br class="d-none d-lg-block" />digitalization of the dental industry.</p>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </sub-section>

        <!-- <sub-section>
            <v-row>
                <v-col cols="12" md="4" lg="5">
                    <div class="img-wrap img-wrap--left-gray-bg">
                        <v-img max-width="408" src="/images/sub/story/values/overview-img.png" />
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="8" lg="6">
                    <div class="mt-20 mt-md-0">
                        <div class="sub-tit-wrap">
                            <h3 class="sub-tit">Overview</h3>
                            <div class="tit-wrap">
                                <h2 class="tit tit--sm">Innovation for Humanity, Quality Absoluteism Dentium's beliefs change the world</h2>
                            </div>
                        </div>
                        <div class="page-text">
                            <p>Dentium Co., Ltd. is a dental medical device company that produces implant products and was established in June 2000 with the participation of dentists and experts with numerous clinical experience know-how.</p>
                            <p>Since its establishment, Dentium Implant has grown into a global company that exports implant products to more than 70 countries, including Europe, China, Russia, the Middle East, Asia and Latin America, in recognition of the stability and excellence of its products, including the U.S. FDA.</p>
                            <p>Under the motto of Total Solution Provider &amp; Self Development Manufacturer, We are building an integrated implant system that encompasses the highest quality dental implants, biomaterials, and dental digital medical devices by securing differentiated technology through R&amp;D to enter the global TOP6 in 2020.</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </sub-section>

        <sub-section class="pt-0">
            <v-row>
                <v-col cols="12" md="8" lg="6" order-md="1">
                    <div class="mt-20 mt-md-0">
                        <div class="sub-tit-wrap">
                            <h3 class="sub-tit">Greeting</h3>
                            <div class="tit-wrap">
                                <h2 class="tit tit--sm">The dental medical device market Leading Dentium Implants</h2>
                            </div>
                        </div>
                        <div class="page-text">
                            <p>The Dentium R&amp;D Center, a key engine of dentium, has built a state-of-the-art facility system that can be stabilized and validated, and is actively eveloping and researching products in cooperation with the world's leading universities and specialized research institutes.</p>
                            <p>Dentium's years of clinical experience have been recognized by all customers around the world, and excellent bone adhesion responses, which are more stable and minimize bone loss, have already shown excellent results in many clinical treatments and papers and become a global brand in dental medicine.</p>
                            <p>In addition, we provide excellent educational programs through seminars in Korea, including "DENTIUM IMPLANT INSTITUTE," and hold an international Symposium and Case Presentation every year to provide a venue for information exchange between scholars and specialists around the world.</p>
                            <p>Dentium continues to realize continuous creative innovation through continuous research and development, and promises to provide the best products and services to our customers as a market leader.</p>
                            <p class="font-weight-medium grey-16--text">All employees of Dentium Co., Ltd</p>
                        </div>
                    </div>
                </v-col>
                <v-spacer class="order-2" />
                <v-col cols="12" md="4" lg="5" order="1" order-md="3">
                    <div class="img-wrap img-wrap--right-gray-bg">
                        <v-img max-width="408" src="/images/sub/story/values/overview-img2.png" />
                    </div>
                </v-col>
            </v-row>
        </sub-section>

        <v-divider /> -->

        <sub-section class="page-section--last pb-0">
            <template slot="pageSectionImmersive">
                <div class="tit-wrap text-center">
                    <h2 class="tit">Brand Story</h2>
                </div>

                <v-row no-gutters>
                    <v-col cols="12" md="4" class="min-h-lg-400px">
                        <v-card tile elevation="0" height="100%" class="d-flex align-center justify-center" img="/images/sub/story/values/overview-bg4.jpg">
                            <div class="px-20 py-40 pa-lg-20 text-center">
                                <div class="tit-wrap tit-wrap--line text-center mb-16 mb-lg-24">
                                    <h2 class="tit tit--sm white--text">The Trusted Dentium</h2>
                                </div>
                                <p class="page-text page-text--lg grey-c--text">Dentium implants add value over time <br class="d-none d-sm-block" />10 processes, 10 tests, 100% self-production</p>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" class="min-h-lg-400px">
                        <v-card tile elevation="0" height="100%" class="d-flex align-center justify-center" img="/images/sub/story/values/overview-bg5.jpg">
                            <div class="px-20 py-40 pa-lg-20 text-center">
                                <div class="tit-wrap tit-wrap--line text-center mb-16 mb-lg-24">
                                    <h2 class="tit tit--sm white--text">Dentium in Research</h2>
                                </div>
                                <p class="page-text page-text--lg grey-c--text">Market-leading creative innovation <br class="d-none d-sm-block" />Dentium R&amp;D Center</p>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" class="min-h-lg-400px">
                        <v-card tile elevation="0" height="100%" class="d-flex align-center justify-center" img="/images/sub/story/values/overview-bg6.jpg">
                            <div class="px-20 py-40 pa-lg-20 text-center">
                                <div class="tit-wrap tit-wrap--line text-center mb-16 mb-lg-24">
                                    <h2 class="tit tit--sm white--text">The Challenging Dentium</h2>
                                </div>
                                <p class="page-text page-text--lg grey-c--text">Exports to over 70 countries around the world. <br class="d-none d-sm-block" />A leap into the world</p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import StoryValuesChips from "@/components/client/sub/story-values-chips.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubDepthTabWrap,
        SubSection,
        StoryValuesChips,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .overview-img-wide {
        margin-left: -18.3333%;
        &.col-md-6 {
            flex: 0 0 calc(50% + 18.3333%);
            max-width: calc(50% + 18.3333%);
        }
    }
}
@media (min-width: 1200px) {
}
</style>
