var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "About Us",
            "tabActive": "Values",
            "src": "/images/sub/sv-story-values-overview.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-depth-tab-wrap', [_c('v-container', [_c('story-values-chips')], 1)], 1), _c('sub-section', {
    staticClass: "page-section--first"
  }, [_c('div', {
    staticClass: "page-section__bg",
    staticStyle: {
      "background-image": "url(/images/sub/story/values/overview-bg.jpg)"
    }
  }), _c('div', {
    staticClass: "tit-wrap mb-lg-60 text-center"
  }, [_c('h3', {
    staticClass: "tit tit--sm secondary--text mb-10 mb-lg-14"
  }, [_vm._v("Vision")]), _c('h2', {
    staticClass: "tit font-size-lg-52"
  }, [_vm._v("To be used widely, with good product.")])]), _c('div', {
    staticClass: "mb-30 mb-md-40 mb-lg-60"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1200",
      "aspect-ratio": 1200 / 562,
      "src": "/images/sub/story/values/overview-img.jpg"
    }
  })], 1), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-10 py-md-0 text-center"
  }, [_c('div', {
    staticClass: "mb-16 mb-lg-24"
  }, [_c('p', {
    staticClass: "font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1"
  }, [_vm._v("1")])]), _c('div', [_c('p', {
    staticClass: "font-size-16 font-size-md-18 font-spoqa font-weight-medium"
  }, [_vm._v("Quality first "), _c('br', {
    staticClass: "d-sm-none d-md-block"
  }), _vm._v("principle")])])])]), _c('v-col', {
    staticClass: "d-sm-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "d-none d-sm-block ml-n1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-10 py-md-0 text-center"
  }, [_c('div', {
    staticClass: "mb-16 mb-lg-24"
  }, [_c('p', {
    staticClass: "font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1"
  }, [_vm._v("10")])]), _c('div', [_c('p', {
    staticClass: "font-size-16 font-size-md-18 font-spoqa font-weight-medium"
  }, [_vm._v("More than 10 years of "), _c('br', {
    staticClass: "d-sm-none d-lg-block"
  }), _vm._v("maintenance verification")])])])]), _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "d-none d-md-block ml-n1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-10 py-md-0 text-center"
  }, [_c('div', {
    staticClass: "mb-16 mb-lg-24"
  }, [_c('p', {
    staticClass: "font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1"
  }, [_vm._v("70")])]), _c('div', [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("Exports to over 70 countries "), _c('br', {
    staticClass: "d-sm-none d-lg-block"
  }), _vm._v("around the world")])])])]), _c('v-col', {
    staticClass: "d-sm-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "d-none d-sm-block ml-n1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-10 py-md-0 text-center"
  }, [_c('div', {
    staticClass: "mb-16 mb-lg-24"
  }, [_c('p', {
    staticClass: "font-size-26 font-size-lg-78 font-weight-medium secondary--text line-height-1"
  }, [_vm._v("98")])]), _c('div', [_c('p', {
    staticClass: "font-size-16 font-size-md-18 font-spoqa font-weight-medium"
  }, [_vm._v("A high success rate of "), _c('br', {
    staticClass: "d-sm-none d-lg-block"
  }), _vm._v("98 percent")])])])])], 1)], 1), _c('sub-section', {
    staticStyle: {
      "background-image": "url(/images/sub/story/values/overview-bg2.jpg)"
    }
  }, [_c('div', {
    staticClass: "mb-40 mb-md-50 mb-lg-90 text-center"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "758",
      "aspect-ratio": 758 / 200,
      "src": "/images/sub/story/values/overview-tit.png"
    }
  })], 1), _c('v-row', {
    staticClass: "my-n16"
  }, [_c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "384",
      "aspect-ratio": 384 / 208,
      "src": "/images/sub/story/values/overview-img2.jpg"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-16 text-center"
  }, [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("Pre-op 2001.11.10")])])], 1), _c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "384",
      "aspect-ratio": 384 / 208,
      "src": "/images/sub/story/values/overview-img3.jpg"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-16 text-center"
  }, [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("Implantation 2001.11.28")])])], 1), _c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "384",
      "aspect-ratio": 384 / 208,
      "src": "/images/sub/story/values/overview-img4.jpg"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-16 text-center"
  }, [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("Implantation 2001.11.28")])])], 1), _c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "384",
      "aspect-ratio": 384 / 208,
      "src": "/images/sub/story/values/overview-img5.jpg"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-16 text-center"
  }, [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("4 Year Follow-up 2005.05.23")])])], 1), _c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "384",
      "aspect-ratio": 384 / 208,
      "src": "/images/sub/story/values/overview-img6.jpg"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-16 text-center"
  }, [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("11 Year Follow-up 2013.01.17")])])], 1), _c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "384",
      "aspect-ratio": 384 / 208,
      "src": "/images/sub/story/values/overview-img7.jpg"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-16 text-center"
  }, [_c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("20 Year Follow-up 2021.04.14")])])], 1)], 1)], 1), _c('sub-section', {
    staticStyle: {
      "background-image": "url(/images/sub/story/values/overview-bg3.jpg)"
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-lg-60 text-center"
  }, [_c('h2', {
    staticClass: "tit font-size-lg-52"
  }, [_vm._v("Global Dentium")])]), _c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "486",
      "aspect-ratio": 486 / 486,
      "src": "/images/sub/story/values/overview-img8.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "pt-10 pt-md-0"
  }, [_c('v-divider', {
    staticClass: "primary mb-16 mb-md-24 w-30px",
    staticStyle: {
      "border-width": "4px 0 0"
    }
  }), _c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("Dentium is a manufacturer that provides world-class "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("dental implant systems and solutions to clinicians in over 70 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("countries worldwide.")])], 1)])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-40 mt-lg-60"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "486",
      "aspect-ratio": 486 / 486,
      "src": "/images/sub/story/values/overview-img9.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pt-10 pt-md-0"
  }, [_c('v-divider', {
    staticClass: "primary mb-16 mb-md-24 w-30px",
    staticStyle: {
      "border-width": "4px 0 0"
    }
  }), _c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("In the future, Dentium will countinue to progress in creating "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("a practical and use-friendly treatment environment through "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("the simplification and digitalization of the dental industry.")])], 1)])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-40 mt-lg-60"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "overview-img-wide",
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mr-lg-0",
    attrs: {
      "max-width": "834",
      "aspect-ratio": 834 / 600,
      "src": "/images/sub/story/values/overview-img10.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "pb-lg-200"
  }, [_c('v-divider', {
    staticClass: "primary mb-16 mb-md-24 w-30px",
    staticStyle: {
      "border-width": "4px 0 0"
    }
  }), _c('p', {
    staticClass: "font-size-16 font-size-lg-18 font-spoqa font-weight-medium"
  }, [_vm._v("In the future, Dentium will move forward to create a practical and "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("simple treatment environment through minimalism and "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("digitalization of the dental industry.")])], 1)])], 1)], 1)]), _c('sub-section', {
    staticClass: "page-section--last pb-0"
  }, [_c('template', {
    slot: "pageSectionImmersive"
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Brand Story")])]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "min-h-lg-400px",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "height": "100%",
      "img": "/images/sub/story/values/overview-bg4.jpg"
    }
  }, [_c('div', {
    staticClass: "px-20 py-40 pa-lg-20 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--line text-center mb-16 mb-lg-24"
  }, [_c('h2', {
    staticClass: "tit tit--sm white--text"
  }, [_vm._v("The Trusted Dentium")])]), _c('p', {
    staticClass: "page-text page-text--lg grey-c--text"
  }, [_vm._v("Dentium implants add value over time "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v("10 processes, 10 tests, 100% self-production")])])])], 1), _c('v-col', {
    staticClass: "min-h-lg-400px",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "height": "100%",
      "img": "/images/sub/story/values/overview-bg5.jpg"
    }
  }, [_c('div', {
    staticClass: "px-20 py-40 pa-lg-20 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--line text-center mb-16 mb-lg-24"
  }, [_c('h2', {
    staticClass: "tit tit--sm white--text"
  }, [_vm._v("Dentium in Research")])]), _c('p', {
    staticClass: "page-text page-text--lg grey-c--text"
  }, [_vm._v("Market-leading creative innovation "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v("Dentium R&D Center")])])])], 1), _c('v-col', {
    staticClass: "min-h-lg-400px",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "height": "100%",
      "img": "/images/sub/story/values/overview-bg6.jpg"
    }
  }, [_c('div', {
    staticClass: "px-20 py-40 pa-lg-20 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--line text-center mb-16 mb-lg-24"
  }, [_c('h2', {
    staticClass: "tit tit--sm white--text"
  }, [_vm._v("The Challenging Dentium")])]), _c('p', {
    staticClass: "page-text page-text--lg grey-c--text"
  }, [_vm._v("Exports to over 70 countries around the world. "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v("A leap into the world")])])])], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }