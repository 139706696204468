<template>
    <v-tabs-grey v-if="tabsGrey90" v-bind="{ value }">
        <v-tab v-for="{ text, value, to } in tabs" :key="value">
            <v-btn v-bind="{ ...$attrs, value, to }" text color="grey-90" class="v-btn--none-active-bg">{{ text }}</v-btn>
        </v-tab>
    </v-tabs-grey>
    <v-tabs v-else v-bind="{ value }">
        <v-tab v-for="{ text, value, to } in tabs" :key="value">
            <v-btn v-bind="{ ...$attrs, value, to }" text color="primary" class="v-btn--none-active-bg">{{ text }}</v-btn>
        </v-tab>
    </v-tabs>
</template>

<script>
import VTabsGrey from "@/components/client/dumb/v-tabs-grey.vue";

export default {
    props: {
        value: { type: String, default: null },
        tabs: { type: Array, default: () => [] },
        tabsGrey90:{ type: Boolean, default: false },
    },
    components: {
        VTabsGrey,
    },
};
</script>

<style scoped>
</style>
