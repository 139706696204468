var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "About Us",
            "tabActive": "Values",
            "src": "/images/sub/sv-story-values-rnd.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-depth-tab-wrap', [_c('v-container', [_c('story-values-chips')], 1)], 1), _c('sub-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "mb-40 mb-md-60 mb-lg-100"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "796",
      "aspect-ratio": 796 / 468,
      "src": "/images/sub/story/values/live-img.png"
    }
  })], 1), _c('video', {
    staticClass: "w-100",
    attrs: {
      "controls": "",
      "poster": "/file/live-followup.jpg"
    }
  }, [_c('source', {
    attrs: {
      "src": "/res/LiveFollowup.mp4",
      "type": "video/mp4"
    }
  }), _c('strong', [_vm._v("웹 브라우저가 video를 지원하지 않습니다.")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }