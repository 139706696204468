<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="About Us" tabActive="Values" src="/images/sub/sv-story-values-rnd.png"></sub-visual>
        </template>

        <sub-depth-tab-wrap>
            <v-container>
                <story-values-chips />
            </v-container>
        </sub-depth-tab-wrap>

        <sub-section class="page-section--first page-section--last">
            <div class="mb-40 mb-md-60 mb-lg-100">
                <v-img max-width="796" :aspect-ratio="796 / 468" class="mx-auto" src="/images/sub/story/values/live-img.png" />
            </div>

            <video controls poster="/file/live-followup.jpg" class="w-100">
                <source src="/res/LiveFollowup.mp4" type="video/mp4" />
                <strong>웹 브라우저가 video를 지원하지 않습니다.</strong>
            </video>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import StoryValuesChips from "@/components/client/sub/story-values-chips.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubDepthTabWrap,
        SubSection,
        StoryValuesChips,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style></style>
