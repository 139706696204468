<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="About Us" tabActive="Values" src="/images/sub/sv-story-values-vision-challenge.png"></sub-visual>
        </template>

        <sub-depth-tab-wrap>
            <v-container>
                <story-values-chips tabActive="vision" />
            </v-container>
        </sub-depth-tab-wrap>

        <sub-depth-tab-wrap>
            <v-tabs-sticky>
                <story-values-vision-tabs />
            </v-tabs-sticky>
        </sub-depth-tab-wrap>

        <sub-section class="page-section--first">
            <div class="tit-wrap text-center">
                <h2 class="tit">Exports to 70 countries around the World <br class="d-none d-md-block"/>The Global Dentium</h2>
            </div>

            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile outlined elevation="0" height="100%" class="d-flex align-center justify-center">
                        <div class="pa-30 py-lg-60 px-lg-40">
                            <div class="mb-16 mb-md-24">
                                <v-img max-width="60" class="ma-auto" src="/images/sub/story/values/challenge-icon.png" />
                            </div>
                            <div class="text-center">
                                <div class="font-size-16 font-size-md-18 font-weight-medium grey-2--text">US/China Global <br class="d-none d-md-block"/>Production Base</div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile outlined elevation="0" height="100%" class="d-flex align-center justify-center">
                        <div class="pa-30 py-lg-60 px-lg-40">
                            <div class="mb-16 mb-md-24">
                                <v-img max-width="60" class="ma-auto" src="/images/sub/story/values/challenge-icon2.png" />
                            </div>
                            <div class="text-center">
                                <div class="font-size-16 font-size-md-18 font-weight-medium grey-2--text">Dentium Institute (Dentium World Symposium Dentium Forum, etc)</div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile outlined elevation="0" height="100%" class="d-flex align-center justify-center">
                        <div class="pa-30 py-lg-60 px-lg-40">
                            <div class="mb-16 mb-md-24">
                                <v-img max-width="60" class="ma-auto" src="/images/sub/story/values/challenge-icon3.png" />
                            </div>
                            <div class="text-center">
                                <div class="font-size-16 font-size-md-18 font-weight-medium grey-2--text">Bone fusion grafting material professional development</div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-card tile outlined elevation="0" height="100%" class="d-flex align-center justify-center">
                        <div class="pa-30 py-lg-60 px-lg-40">
                            <div class="mb-16 mb-md-24">
                                <v-img max-width="60" class="ma-auto" src="/images/sub/story/values/challenge-icon4.png" />
                            </div>
                            <div class="text-center">
                                <div class="font-size-16 font-size-md-18 font-weight-medium grey-2--text">14 overseas subsidiaries export more than 70 countries around the world. Technical delegates, including direct sales force</div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-card tile outlined elevation="0" height="100%" class="d-flex align-center justify-center">
                        <div class="pa-30 py-lg-60 px-lg-40">
                            <div class="mb-16 mb-md-24">
                                <v-img max-width="60" class="ma-auto" src="/images/sub/story/values/challenge-icon5.png" />
                            </div>
                            <div class="text-center">
                                <div class="font-size-16 font-size-md-18 font-weight-medium grey-2--text">Establishing a clinical research network <br class="d-none d-md-block"/>(leading overseas universities and <br class="d-none d-md-block"/>domestic dentistry)</div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </sub-section>

        <sub-section class="pt-0 grey-f8">
            <div class="img-wrap--half-bg mb-30 mb-md-56">
                <v-img src="/images/sub/story/values/challenge-img.png" />
            </div>
            <div class="sub-tit-wrap">
                <h3 class="sub-tit">Challenge</h3>
                <div class="tit-wrap">
                    <h2 class="tit tit--sm">2004 Establishment of the first U.S.production plant in Korea, 2003 U.S. FDA, European CE, and more Obtain certification of major overseas countries</h2>
                </div>
            </div>
            <div class="page-text">
                <p>In 2004, the local Industry's First set up a production plant in the United States and strict fda the United States. Food and Drug Administration certification is required in major countries, including overseas. A global technology and products based on mutual trust. Europe, Central Asia, Russia, the Middle East, Asia, etc. Export in over seventy countries all over the world. Korea's leading implants.</p>
            </div>
        </sub-section>

        <sub-section class="page-section--last">
            <div class="tit-wrap text-center mb-40 mb-md-80 mb-lg-110">
                <h2 class="tit">Dentium, <br class="d-none d-md-block"/>Opening a new Generation</h2>
            </div>

            <v-row>
                <v-col cols="12" md="4" lg="5">
                    <div class="img-wrap img-wrap--left-gray-bg">
                        <v-img max-width="408" src="/images/sub/story/values/challenge-img2.png" />
                    </div>
                </v-col>
                <v-spacer/>
                <v-col cols="12" md="8" lg="6">
                    <div class="mt-20 mt-md-0">
                        <div class="sub-tit-wrap">
                            <h3 class="sub-tit">Global</h3>
                            <div class="tit-wrap">
                                <h2 class="tit tit--sm">Opening a new Generation.<br/>To enter the global TOP6 in 2020</h2>
                            </div>
                        </div>
                        <div class="page-text">
                            <p>To enter the global TOP6 in 2020, we secured differentiated technology through
our own R&amp;D We are committed to providing the highest quality implant
system. In addition to the widely recognized dental implant products, dentium
has also been extensively studied in biomaterials for bone grafts, We are
developing various bone regeneration and periodontal tissue regeneration
products such as shielding membranes.</p>
                            <p>In addition, in 2010, the Institute of Convergence Technology (ICT) Business
Headquarters was established to provide a three-dimensional radiation
machine called CBCT CAD/CAM systems are self-developed and integrated
implant systems are established in conjunction with implant systems 'Total
Solution Provider &amp; Self Development Manufacturer' is being realized.</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import VTabsSticky from "@/components/client/dumb/v-tabs-sticky.vue";
import StoryValuesChips from "@/components/client/sub/story-values-chips.vue";
import StoryValuesVisionTabs from "@/components/client/sub/story-values-vision-tabs.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubDepthTabWrap,
        SubSection,
        VTabsSticky,
        StoryValuesChips,
        StoryValuesVisionTabs,
    },
    data () {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>
